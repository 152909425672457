import React from "react"
import { Card, CardTitle, CardText } from 'reactstrap';
import { navigate } from 'gatsby'
import './index.css'

import Layout from "../components/layout"
import SectionWithImg from '../components/sectionWithImg'
import PqButton from '../components/pqButton'
import PqCheck from '../components/pqCheck'
import SEO from "../components/seo"

const CoreValue = ({ value }) => {
  return (
    <li className='core-value-container'>
      <div className='core-value-bullet'></div>
      <div className='core-value-text'>{value}</div>
    </li>
  );
}

const Service = ({ title, text }) => {
  return (
    <Card body className='service'>
      <CardTitle className='service-title'>{ title }</CardTitle>
      <CardText>{ text }</CardText>
    </Card>
  );
}

const IndexPage = ({ path, data }) => (
  <Layout path={path}>
    <SEO title="Home" />
    <SectionWithImg
      title='OUR MISSION'
      subtitle='ABOUT US'
      image={data.missionImage}
      imageWidth='4681'
      imageHeight='3121'
      imageAlt='PriorityQ hard at work serving its clients.'
      imageRight
      dark
    >
      <div className='flex-row flex-col-fix'>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: '1.45rem'
        }}>
          <PqCheck size='2x' hzPadding='1rem' vtPadding='0.75rem' className='home-mission-check'/>
        </div>
        <p className='flex-col-fix' style={{ marginLeft: '0.5rem' }}>
          We provide solid well architected solutions to technological problems that are tailor made for our customers' needs.
          Providing you with solutions to problems in a professional and timely manner is our priority.
          {/* Your prioities are our priorities. */}
        </p>
      </div>
    </SectionWithImg>
    <SectionWithImg
      title='WHAT WE OFFER'
      subtitle='Who We Are'
      image={data.offeringsImage}
      imageAlt='Member of PriorityQ giving a presntation about our offering.'
      imageWidth='4681'
      imageHeight='3121'
    >
      <ul className='list-no-style'>
        <CoreValue value='Industry Experience and Technical Expertise'/>
        <CoreValue value='Commitment to Client Priorities'/>
        <CoreValue value='Agile Development Process'/>
        <CoreValue value='Integrity and Professionalism'/>
        <CoreValue value='Timely Execution'/>
      </ul>
    </SectionWithImg>
    <div className='section-dark full-width-background'>
      <div className='flex-row global-padding services-section'>
        <div className='flex-item'>
          <div className='flex-center services-left-pane'>
            <div className='flex-col-fix'>
              <h6>ABOUT US</h6>
              <h1>SERVICES</h1>
              <p>
                We  specialize in web application development, mobile application development, geospatial solutions and cloud solution architecture.
                Our team has mastered these offerings and can provide technical expertise and guidance in order to find solutions for your problems.
              </p>
              <PqButton onClick={() => navigate('/services')}>Read More</PqButton>
            </div>
          </div>
        </div>
        <div className='flex-item'>
          <h1 className='services-title-mobile'>SERVICES</h1>
          <Service
            title='Web/Mobile Application Development'
            text='We build custom web and mobile applications with a focus on responsive design, accessibility, and intuitive user experience.'
          />
          <Service
            title='Geospatial Application Development'
            text='We provide geospatial solutions focusing on data analytics, data visualization, and user experience.'
          />
          <Service
            title='Cloud Solutions Architecture'
            text='We have a team of skilled Cloud Solutions Architetcs that are equipped to build highly available and disaster resistant infrastructure.'
          />
          <Service
            title='Business Intelligence'
            text='We provide services that help you extract/transform data and provide meaningful insights.'
          />
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    missionImage: file(relativePath: { eq: "mission.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    offeringsImage: file(
      relativePath: { eq: "companyHighLevel.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
